import React, { Component } from "react";

class Header extends Component {
  render() {
    return (
      <div>
        <h1 class="Title-Home">
          <img src="/images/header-img2.png" alt="example" width="100%" />

          <div className="slogan">
            <h2>Drag Queens versus Zombies</h2>
          </div>
        </h1>
        <nav class="navbar navbar-expand-lg navbar-dark navbar-custom">
        
          <div
            class="justify-content-center"
            id="navbarContent"
          >
            <ul class="navbar-nav">
              <li class="nav-item active">
                <a class="nav-link" href="/">
                  Home
                </a>
              </li>
              
              <li class="nav-item">
                <a class="nav-link" href="/original-play">
                  Original Story
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/short-film">
                  Early Stages
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/action">
                  Support Us
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

<script
  src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.min.js"
  integrity="sha384-QJHtvGhmr9XOIpI6YVutG+2QOK9T+ZnN4kzFN1RtK3zEFEIsxhlmWl5/YESvpZ13"
  crossorigin="anonymous"
></script>;

export default Header;
