import React, { Component } from "react";

class ShortFilm extends Component {
  render() {
    return (
      <div className="body-page">
        <br />
        <div className="upper-container">
          <div className="row-1">
            <div className="col-1">
              <div className="videoWrapper">
                <iframe
                  src="https://www.youtube.com/embed/NycTZB89Iyc?si=O5iOwx60KJNKI3po"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>

            <br />

            <div class="col-1">
              <div className="videoWrapper">
                <iframe
                  src="https://www.youtube.com/embed/amYRHnq0R8o"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <h2>About the Short Film</h2>
        “The Queen and the Dead” follows the life of a young drag songstress,
        Viola Corona also known as Florentino. Viola moves from San Francisco,
        California to Mexico City to follow her dream of becoming the next
        international female pop star only to discover the zombie apocalypse has
        just begun. Viola discovers that her voice has the power to hypnotize
        zombies. This film chronicles Viola’s hilarious butt kicking adventures
        to stardom. Check out the short film that was completed with a budget of
        $5000, an iPhone 5s and 2 days of shooting. 
        <br /><br />
        If you haven’t seen the
        short, grab a bag of popcorn and check it out down below!

        <br /><br />
        <center>
          <h2>Short Film</h2>

          <div className="videoWrapper">
            <iframe
              src="https://www.youtube.com/embed/R7iQaCMjI-s?si=8wV7uWvo2RPmy1iV"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        </center>
        <br />
        <br />
        <div class="row">
          <div class="col">
            <h4>The Cast and Crew</h4>
            Director/Writer/Executive Producer - Antonio Ortiz
            <br />
            Producer/Co-Director - Hector Zavala
            <br />
            <br />
            <h4>Cast (in order of appearance)</h4>
            Neto's Sister - Iliana Becerra
            <br />
            Neto/Stand In - Jose Alberto Velarde
            <br />
            Stand In - Paola Martinez
            <br />
            Oliver/Bell E. Galore - Carlos Barrera
            <br />
            Florentino/Viola Corona - Rodrigo Urquidi
            <br />
            Memo/Sketch - Javier Olivan
            <br />
            Waiter - Isaac Heruz <br />
            Vicky Paredes - Anahí Dávila <br />
            Crying Girl - Ana Lucia Robleda <br />
            Radio News Anchor/Mexico City Zombie - Oscar de la Rosa
            <br />
            Taxi Driver - Martin Nava <br />
            Extra - Mannu Davti
            <br />
            <br />
            <h4>Crew</h4>
            Production Assistant - Isaac Heruz
            <br />
            Director of Photography - Amanda Safa
            <br />
            Cameraman - Rene Hope 
            <br />
            Best Boy/Gaffer - Ernesto Madrigal
            <br />
            Key Make-Up Artist - Ariadna Torres
             <br />
            Mexicali Special FX Artist - Jose Luis Torres *Rojoxido*
            <br />
            Key Sound/Composer/Musical Arrangements - Brandon Torres
            <br />
            <br />
            Logo Designed by Priscila Martinez
            <br />
            Spanish Subtitles provided by Paola Martinez
            <br />
            Some Clothes Provided by Chad Berwald
            <br />
            Catering by Xilonen, Fonda y Tamales Gourmet Special
            <br />
            <h4>Locations:</h4>
            Mexico D.F.- El Barba Azul
            <br />
            BBKFE Mexicali - Centro Cultural CREA Cultura
            <br />
            <br />
          </div>

          <div class="col">
            <div className="cast-pictures">
              <img src="/images/cast-1.jpeg" alt="example" height="900" />
            </div>

            <br />
          </div>
        </div>
        <div className="cast-pictures">
          <img src="/images/cast-4.png" alt="example" height="" />
        </div>
        <div class="row">
          <div class="col">
            <h4>San Francisco Launch Party</h4>
            February 21, 2016 Oasis
          </div>
          <div class="col">
            <h4>San Diego Launch Party</h4>
            February 24, 2016 Digital Gym Cinema
          </div>
          <div class="col">
            <h4>Mexicali Launch Party</h4>
            February 27, 2016 Espacio Artmosferas
          </div>
        </div>
        <br />
        <br />
        Jornada Cultural de Lucha Contra la Homofobia, Lesbofobia, Transfobia...
        May 20, 2016 Mexicali, Mexico
      </div>
    );
  }
}

export default ShortFilm;
