import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Home from "./Home";
import About from "./About";
import OriginalPlay from "./OriginalPlay";
import ShortFilm from "./ShortFilm";
import Action from "./Action";

function App() {
  return (
    <body>
      <>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/original-play" element={<OriginalPlay />} />
            <Route path="/short-film" element={<ShortFilm />} />
            <Route path="/action" element={<Action />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </>
    </body>
  );
}

export default App;
