import React, { Component } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class Home extends Component {
  render() {
    return (
      <div className="body-page">
        <br />
        <div className="upper-container">
          

          <a href="https://www.youtube.com/watch?v=JKFyfebgeZA&t=273s"><img src="/images/tqatd-thumbnail.png" alt="tqatd-thumbnail"  width="100%" /></a>

          <br />
          <br />

          “The Queen and the Dead” is a homage to all superheroes who inspire us
          to be better human beings. In a cold world that is antagonistic toward
          gender nonconforming people, we cannot envision a better hero than a
          drag queen. Move over Superman and Wonder Woman. It's time for a new
          hero - VioLa Corona the Queen of the Dead. It’s a cult classic in the
          making. 
          <br />
          <br />

        </div>

        <div class="movie-link glow">
          <center><a href="https://www.youtube.com/watch?v=JKFyfebgeZA&t=273s" target="_blank">Click to watch on YouTube now!</a></center>
          </div>

        <div>
          <br />
          <div class="cast-crew">
          <div className="center">
            Executive Producer USA/Director/Writer - Antonio T Ortiz Jr<br />
            Production Designer Mexico/Executive Producer - Hector Zavala <br />
            Producers - Susan Owen, Darryl Castro and Kevin Dubay <br />
            Associate Producers - Josiely Hermida, Dean Allemang and Gil Cho{" "}
            <br /><br />
            <h2>CAST</h2>(in order of appearance)<br />
            <br/>
            Bell E. Galore - Hector Zavala
            <br />
            Memo - Mannu Dávti
            <br />
            Viola Corona - Marcelo Javier Pareira
            <br />
            Jesus - Derek Lucken
            <br />
            Badger Network News Host - Cameron Jones
            <br />
            Sangre De Mi Sangre Narrator - Patrick Stephenson
            <br />
            Dominico Ludovic - Jose Alberto Castro
            <br />
            Victoria Maria Paredes Delgado - Vannessa Avendano
            <br />
            Petrona - Norma Bustamante
            <br />
            Camila - Terezina Vital
            <br />
            Sally - Joana Guzman
            <br />
            Jessica - Halicia Ashford
            <br />
            Sobrina - Jimena Fernanda
            <br />
            Tia Vela - Aida Pierce
            <br />
            Grisel Margarita - Grisel Margarita
            <br />
            Roberto Cabral - Roberto Cabral
            <br />
            Coffee Shop Zombie #1 - Rouse Calderon
            <br />
            Coffee Shop Zombie #2 - Hector Alejandro Medel
            <br />
            Guy in Wheelchair - Calum Sierra
            <br />
            Woman Stretching - Anahi Davila
            <br />
            Mexico City Zombie #1 - Juan Tepoc
            <br />
            Mexico City Zombie #2 - Esteban Tepoc
            <br />
            Mexico City Woman Running away - Ilse Santana
            <br />
            Mexico City Zombie #3 - Daniel García
            <br />
            Pharmacy Zombie - Nayeli Vallejo
            <br />
            Radio Voice 1 Male - Oscar de la Rosa
            <br />
            Apartment Zombie - Efren Zamaniego
            <br />
            Dancing Zombies - Itza Urquidez, Andrea Corona Dufao
            <br />
            Parque Hidalgo Street Zombies - Antonio Resendez, Gerardo Avena
            <br />
            Chevron Alley Zombie #1 - Sandra Guadalupe Macias, Caro García,
            Jovhan Garcia
            <br />
            Salon De Eventos Zombies - Martin Gaxiola, Keila Vega, Adrián
            Mendoza, Santos Ramirez
            <br />
            Zombies eating Sally - Jose Quiroz, Cheche Gomez
            <br />
            Zombie in Salon - Luis Marmolejo
            <br />
            Zombie Mesmerized by Singing - Vanessa Salcido
            <br />
            Zombie Chasing Bell - Armando Echeverria
            <br />
            Heklina - Self
            <br />
            Homeless Person - Chad Berwald
            <br />
            Alley Zombie - Zelda Koznofski
            <br />
            <br />
            <h2>CREW</h2>
            1st Assistant Director Mexicali - Ignacio Villaseñor
            <br />
            2nd Assistant Director Mexicali - Rodrigo Meillon
            <br />
            2nd Second Assistant Director Mexicali- Hector Ibarra
            <br />
            Key Set Production Assistant Mexicali - Christian Milan
            <br />
            1st Assistant Director Mexico City/Post Production Producer - Amanda
            Zafa
            <br />
            Production Manager - Danna Pedraza
            <br />
            Line Producer/UPM - Marilyn Romero
            <br />
            <br />
            Director of Photography - Alan Murillo
            <br />
            Camera Operator - Juan M. Zamora
            <br />
            1st Assistant Camera - Anastacia Romero
            <br />
            Grip - Fausto Cesar Soto
            <br />
            Grip - Jose Angel Serrano
            <br />
            <br />
            Lead Makeup FX - José Luis Torres aka Rojoxido
            <br />
            Assistant Makeup FX - Efrén Samaniego Velderrain
            <br />
            <br />
            Lead Drag Makeup - Silvia Zuñiga
            <br />
            Final Scene Drag Makeup Consultant - Kendra Ruiz Beltran
            <br />
            <br />
            Art Director - Paula Mendoza Niebla
            <br />
            Art Assistant/Data Manager/Still Photography - Pamela Damayanty
            <br />
            <br />
            Costume Designer - Yvonne Ortiz Taylor
            <br />
            Costume Assistant - Chad Berwald
            <br />
            Costumer - Ilse Santana
            <br />
            <br />
            Sound Designer - Brandon Torres
            <br />
            Sound Recording Production Mixer - Jorge Armenta
            <br />
            Sound Recording Production Mixer - Berenice Medel
            <br />
            Sound Assistant - Hector Robles
            <br />
            Sound Assistant - Miguel Villaseñor
            <br />
            <br />
            Film Editor Patrick Stephenson
            <br />
            Subtitles - Sonia Paola Martínez Zavala
            <br />
            <br />
            Music Composer of Price to Pay and Singing for Our Lives - Lawrence
            Rush
            <br />
            Price to Pay and Singing for Our Lives Producers - Dustin Bankston
            and Dave Burdios-Woemmel
            <br />
            <br />
            PR (Mexico) - Jovanka Kuljacha
            <br />
            <br />
            Merchandise Logo Designer - Priscila Carolina Martínez Zavala
            <br />
            TQTD T-Shirt Designer - Patricia Ávila
            <br />
            <br />
            Special thanks to
            <br />
            <br />
            Prudencia and Antonio T Ortiz Sr
            <br />
            Socorro Amirola Hernández and Ramón Zavala Tirado
            <br />
            Sonia Zavala Amirola and Priscilano Martínez García
            <br />
            Selene Nevarez Estrada of Artmosfera
            <br />
            Diana Limon of La Mina
            <br />
            Heklina of SF Oasis
            <br />
            Kaara Kenisse Alkala of Karavinne
            <br />
            Christian Gainsley and Elizabeth Kohnke of San Francisco
            <br />
            And Mayra Chang of Mexicali
            <br />
            <br />
            Also, thank you to the following for your financial support
            <br />
            <br />

            Shitij Agarwal, Sarah Apilado-Macasadia, Renee Asteria, Jeff Becker,
            La Tesha Brown, Dirk Burns, Maria Carlson, Mason Carroll, Scott
            Carter, Casey Chan-Ruthenbeck, Wesley Chang, Lisa
            Ching, Jesse Christensen, Albert and Kimberly Cirineo, Anthony
            Cisneros, Ken Coelho, Daina Coffey, Jennifer Coffey, Tamar Cohen,
            Charlene Corpus, Joni and Jason Cox, Melissa Craven, Ed Decker,
            Michael DeLeon, Lisa Dettmer, Aleks Donev, Danielle Doyle, Ellen
            Dubrowin, Reggie Dugard, Becky Escobedo Coffey, Maria Fortez,
            Preston Frazier, Lila Ann Frechette, Donald Fritsche, Thomas
            Gallagher, Juan Garfias, Hernan Gomez, Christian Gonzalez, Ellen
            Greene, Miguel Guerrero, Pattaraporn Hermida, Junick Hermida, Holly
            Hessinger, Terry Hudson, Lailie Ibrahim, Chalermpol ‘Paul’
            Jittagasem, Soad Kader, Josh Kahn, Carl Kanzaki, Annabelle King, Ben
            Koo, Trinh Le, Molly Rose Lewis, Ashley Rae Little, Eric Loya,
            Sandra Lupien, Philip Maldari, Priscilla Mancilla, Sage Mandzik,
            Michael Manoochehri, Donald Martin, Eva Martinez, Kelli McCusker,
            Babak Mehraeen, Lisa Melton, Diane Misumi, Veronica Moscoso, Tina
            Novero Koo, Cesar Ochoa, Yvette Ortega, Christine Ortiz, Joseph
            Ortiz, Vanessa Ortiz, Iejnno and Jennifer Ortiz, Roxanne Ortiz,
            Anthony Palombit, John ‘Juanita’ Paschal, Elaine Pelonia, Jean
            Peters, Scott Pham, Hieu Pham, Sally Phillips, Lisa Melton Pizzulo,
            Laura Prives, Lizzie Quinonez, Lorenzo Ramsey, Christine Revelo-Lee,
            Lisa Rothman, Judith Scherr, Nina Serrano, Erin and Andrew Shugard,
            Steven Smith, Jillian Sobol, Veronica Howard Soto, Frank Sterling,
            Tess Sweet, Kevin Earl Taylor, Melvin Terry, Mario Torrigino, Kelly
            Tsai, Bryan Tucker, Lila Valentina, Partha Vasisht, Joseph Williams,
            Skye Wilson, Miki Yamamoto, Michael Yoshida and to the other people
            forgotten and wanting to remain anonymous.
            <br />
            </div>
          </div>

          <br />
          <br />
        </div>

        {/*<a href="https://totolproductions.com/">Totol Productions</a>

        Shitij Agarwal, Sarah Apilado-Macasadia, Renee Asteria, Jeff Becker,
            La Tesha Brown, Dirk Burns, Maria Carlson, Mason Carroll, Scott
            Carter, Casey Chan-Ruthenbeck, Wesley Chang, Lisa
            Ching, Jesse Christensen, Albert and Kimberly Cirineo, Anthony
            Cisneros, Ken Coelho, Daina Coffey, Jennifer Coffey, Tamar Cohen,
            Charlene Corpus, Joni and Jason Cox, Melissa Craven, Ed Decker,
            Michael DeLeon, Lisa Dettmer, Aleks Donev, Danielle Doyle, Ellen
            Dubrowin, Reggie Dugard, Becky Escobedo Coffey, Maria Fortez,
            Preston Frazier, Lila Ann Frechette, Donald Fritsche, Thomas
            Gallagher, Juan Garfias, Hernan Gomez, Christian Gonzalez, Ellen
            Greene, Miguel Guerrero, Pattaraporn Hermida, Junick Hermida, Holly
            Hessinger, Terry Hudson, Lailie Ibrahim, Chalermpol ‘Paul’
            Jittagasem, Soad Kader, Josh Kahn, Carl Kanzaki, Annabelle King, Ben
            Koo, Trinh Le, Molly Rose Lewis, Ashley Rae Little, Eric Loya,
            Sandra Lupien, Philip Maldari, Priscilla Mancilla, Sage Mandzik,
            Michael Manoochehri, Donald Martin, Eva Martinez, Kelli McCusker,
            Babak Mehraeen, Lisa Melton, Diane Misumi, Veronica Moscoso, Tina
            Novero Koo, Cesar Ochoa, Yvette Ortega, Christine Ortiz, Joseph
            Ortiz, Vanessa Ortiz, Iejnno and Jennifer Ortiz, Roxanne Ortiz,
            Anthony Palombit, John ‘Juanita’ Paschal, Elaine Pelonia, Jean
            Peters, Scott Pham, Hieu Pham, Sally Phillips, Lisa Melton Pizzulo,
            Laura Prives, Lizzie Quinonez, Lorenzo Ramsey, Christine Revelo-Lee,
            Lisa Rothman, Judith Scherr, Nina Serrano, Erin and Andrew Shugard,
            Steven Smith, Jillian Sobol, Veronica Howard Soto, Frank Sterling,
            Tess Sweet, Kevin Earl Taylor, Melvin Terry, Mario Torrigino, Kelly
            Tsai, Bryan Tucker, Lila Valentina, Partha Vasisht, Joseph Williams,
            Skye Wilson, Miki Yamamoto, Michael Yoshida and to the other people
            forgotten and wanting to remain anonymous.

        <Container>
          <Row>
            <Col>
              <Link to="/about">
                <img
                  src="/images/movie-img.png"
                  alt="example"
                  width="370"
                  height="300"
                />
              </Link>

              <h5>The Movie</h5>
            </Col>
            <Col>
              <Link to="/short-film">
                <img
                  src="/images/short-img.jpeg"
                  alt="example"
                  width="370"
                  height="300"
                />
              </Link>
              <h5>The Short</h5>
            </Col>
            <Col>
              <Link to="/original-play">
                <img
                  src="/images/original-img.jpeg"
                  alt="example"
                  width="370"
                  height="300"
                />
              </Link>
              <h5>How It Began</h5>
            </Col>
          </Row>
        </Container>
      */}
      </div>
    );
  }
}

export default Home;
