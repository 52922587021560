import React, { Component } from "react";
import { Link } from "react-router-dom";

class Action extends Component {
  render() {
    return (
      <div className="body-page">
        <br />
        <br />
        <center>
          <a href="https://gofund.me/e61de62e">
            <img src="/images/gofundme-logo.webp" alt="example" height="200" />
          </a>

          <div
            class="gfm-embed"
            data-url="https://www.gofundme.com/f/DragQueenvsZombies/widget/large"
          ></div>
          <script
            defer
            src="https://www.gofundme.com/static/js/embed.js"
          ></script>
<br /><br />
From the beginning, when this story of Drag Queens fighting zombies was created in 2014 for the Home Theater Festival in Oakland, California, we needed the support of friends, family and the community to transform it into a short trailer. This support allowed us to fundraise and present the short film, which in turn, allowed us to fundraise and present the film.
<br /><br />

Support came in the form of in-kind donations of locations, equipment, time, and also came in the form of monetary support which helped us pay actors and crew. 10 years to completion from conception to this website meant that we worked when there were funds and paused when there was none.
<br /><br />

A big Thank You to everyone who helped us complete this project!
<br /><br />

If you liked the film, consider supporting us financially by clicking on the gofundme link to help us reach our goal. The additional funds will help us produce future projects.
<br /><br />

        </center>
      </div>
    );
  }
}

export default Action;
