import React, { Component } from "react";

class About extends Component {
  render() {
    return (
      <p>hi</p>
    );
  }
}

export default About;
