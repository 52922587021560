import React, { Component } from "react";
import ReactAudioPlayer from "react-audio-player";

class OriginalPlay extends Component {
  render() {
    return (
      <div className="body-page">
        <h2>Improvisational Play</h2>
        Bell E. Galore (a.k.a. Candy) wakes up hungover in her Oakland home
        wanting peace and quiet before her drag show in San Francisco. Her
        partner Rick prepares dinner and helps her get ready for the show, but
        neighbors Sandy and Brooklyn disturb the peace. Brooklyn is loud and
        argumentative, plus Sandy is a paranoid extreme survivalist who believes
        Oakland is in the beginnings of the Zombie Apocalypse. Sandy tries to
        convince everyone to wait out the chaos in her mountain bunker, but they
        see civil unrest as just a normal day. As the evening progresses, a
        radio newscast announces that Oakland is on lock down, the Bay Bridge is
        closed, there's a city wide curfew with numerous road closures and BART
        has stopped running. Will Bell get to her show before the curtain opens?
        <br />
        <br />
        <div class="center">
          <div class="row-1">
            <div class="col">
              <div class="pictures-1">
                <img
                  src="/images/company-group-img.JPG"
                  alt="example"
                  width="100%"
                  height=""
                />
              </div>
            </div>
            <center>
              <div className="videoWrapper">
                <iframe
                  src="https://www.youtube.com/embed/zhK-FrWZuPs?si=4Uj12dkUXTU-q8WX"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>

            </center>
            <div class="col">
              <h2>Cast and Crew</h2>
              Director/Producer - Antonio Ortiz
              <br />
              Stage Manager - Samantha Young
              <br />
              Bell E. Galore/Candy - Hector Zavala
              <br />
              Rick Esperanza - Antonio Ortiz
              <br />
              Sandy - Danielle Doyle
              <br />
              Brooklyn/Zombie - Jae Michaels
              <br />
              <h2>Camera Crew</h2>
              Camera 1 - Christian Gainsley
              <br />
              Camera 2 - Elisabeth Kohnke
              <br />
            </div>
            <div class="col-1">
              <h2>Radio Cast (In order of appearance)</h2>
              Sarah Jackson/Love Vibrations Host - Erica Bridgeman
              <br />
              Rude Awakening/The Real Deal Host - Sabrina Jacobs
              <br />
              Pamela Crusader - Helen Gould
              <br />
              Dr. Dorothy Laval - Susannah Wood
              <br />
              Bob Norman/KDED News Anchor - Cameron Jones
              <br />
              Officer Stevens - Jamie Cohen
              <br />
              Summer Murdock/Reporter - Saadia Malik
              <br />
              Lola Rodriguez - Jessica Damian
              <br />
              Frank Marshall/Funeral Director - Scott Vermeire
              <br />
              "Fresh"/Activist - Amanda Shepard
              <br />
              Mathilde Betton/Reporter - Mathilde Betton
              <br />
              Gerald Chen - ?<br />
              Josh Sullivan - CS Soong
              <br />
              <br />
              Script Editor - Mark Mericle
            </div>{" "}
            <br />
            <br />
            <h2>Excerpt from KDED Radio</h2>
            <br />
            <ReactAudioPlayer
              src="./audio/sound.mp3"
              style={{ width: "75%" }}
              color="#000000"
              controls
            />
            <br />
          </div>
        </div>
      </div>

      /*CONTENT FOR PAGE -
          audio clips of KDED Radio Broadcast. From the Home Theater Festival,
          June 5-7, 2015 in Oakland, CA*/
    );
  }
}

export default OriginalPlay;
