import React, { Component } from "react";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";

class Footer extends Component {
  render() {
    return (
      <footer>
        <br />
        <center>
          <center>
            <h3>Totol Productions</h3>
          </center>
          <a
            class="social-media"
            href="https://www.facebook.com/thequeenandthedead"
          >
            <FaFacebook />
          </a>

          <a
            class="social-media"
            href="https://www.youtube.com/@thequeenandthedead/videos"
          >
            <FaYoutube />
          </a>
          <br />
          <br />
          <p>Copyright ⓒ {2024}</p>
        </center>
      </footer>
    );
  }
  /* 
  <a class="social-media" href="https://instagram.com">
            <FaInstagram />
          </a>

          <a class="social-media" href="https://twitter.com">
            <FaTwitter />
          </a>
  
  
  
  */
}

export default Footer;
